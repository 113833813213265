define(['app', '$location'], function (app, $location) {

  const accountLoyaltyHubCard = function() {

    const component = {};

    component.config = {
      selectors: {
        errorMessage: '.loyaltyHub_errorMessage'
      },
      dataAttributes: {
        customerId: 'data-customer-id',
        optIn: 'data-opt-in',
      },
    }

    component.init = (element) => {
      component.element = element;
      component.customerId = component.element.getAttribute(component.config.dataAttributes.customerId);
      component.optIn = component.element.getAttribute(component.config.dataAttributes.optIn);
      component.errorMessage = document.querySelector(component.config.selectors.errorMessage).firstChild;

      component.addEventListeners();

      return component;
    };

    component.addEventListeners = () => {
      component.element.addEventListener('click', component.update);
    }


    component.update = () => {
      component.addGTMTracking();
      app.ajax.get({
        url: `/setAccountLoyalty.account?customerId=${component.customerId}&optedIn=${component.optIn}`,
        success: component.updateSuccess,
        error: component.updateError,
      });
    }

    component.updateSuccess = (result) => {
      if(result) {
        $location.reload();
      }
    };

    component.addGTMTracking = () => {
      app.publish('tracking/record', 'Account', 'Rewards', component.optIn === 'true' ? 'opt-in': 'opt-out');
    }

    component.updateError = () => {
      component.errorMessage.style.display = "block";
    };

    return component;

  };

  return accountLoyaltyHubCard;
});
